<template>
  <div>
    <div class="header">
      <div class="leftTiles">
        <div class="navs">
          <div class="navs_content">
            <div>
              <img
                :src="platInfo.logo_url"
                class="plat-logo"
                @click="$utils.goIndexPage"
                alt=""
              />
              <div
                @mouseenter="mouseenterNav(item)"
                @mouseleave="item.open = false"
                v-for="(item, index) in navBarTop"
                :key="index"
              >
                <template v-if="item.is_on">
                  <div @click="pageBtn(item)">
                    {{ item.name }}
                  </div>
                  <div v-if="item.open && item.children" class="shows">
                    <div v-for="(its, ind) in item.children" :key="ind">
                      <template v-if="its.is_on">
                        <div class="erjis" @click="pageBtn(its)">
                          {{ its.name }}
                          <span
                            v-if="its.has_children"
                            class="el-icon el-icon-arrow-right"
                          ></span>
                        </div>
                        <div
                          @click="pageBtn(it)"
                          class="sanjis"
                          v-for="(it, ins) in its.children"
                          :key="ins"
                        >
                          <template v-if="it.is_on">
                            {{ it.name }}
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- 是否登录 -->
        <div class="company-info" v-if="status == '1'">
          <el-popover trigger="hover" class="reset-red">
            <el-radio-group v-model="company_id" @change="mainCompany">
              <div
                v-for="(item, index) in staffList"
                style="margin: 10px 0"
                :key="index"
              >
                <el-radio :label="item.company_id">
                  <span style="color: #333333">{{ item.company_name }}</span>
                </el-radio>
              </div>
            </el-radio-group>
            <span
              style="font-weight: 800; color: #0088fe"
              class="el-icon-sort icon"
              slot="reference"
            ></span>
          </el-popover>
          <span @click="gotoOther" class="companyInfo ellipsisText">
            {{ userMsg.company.name }}
          </span>
          <img
            class="avatar-img"
            :src="userMsg.avatar"
            v-if="userMsg.avatar"
            @click="$router.push('/mySelf/index')"
            alt=""
          />
        </div>
        <div v-else class="login-flex">
          <div class="login" @click="logins">登录</div>
          <div class="login" @click="resgister">用户注册</div>
          <div class="login" @click="qiyeruzhu">企业入驻</div>
          <!-- <div class="logins" @click="resgister">立即注册</div> -->
        </div>
      </div>
    </div>
    <div class="header-margin"></div>
    <!-- 设置登录密码 -->
    <el-dialog title="登录验证" :visible.sync="loginCode.showLog" width="550px">
      <div style="margin: 0 10px">
        <div>
          <span class="blues">①验证初始密码</span>
          <span :class="loginCode.step == 2 ? 'blues' : ''">
            —— ② 修改密码
          </span>
        </div>
        <div v-show="loginCode.step == 1" style="margin-top: 20px">
          <el-input
            clearable
            show-password
            style="width: 400px"
            size="small"
            v-model="loginCode.datas.first_pwd"
            placeholder="初始密码"
          ></el-input>
        </div>
        <div v-show="loginCode.step == 2" style="margin-top: 20px">
          <el-input
            clearable
            show-password
            style="width: 400px"
            size="small"
            v-model="loginCode.datas.password"
            placeholder="新密码"
          ></el-input>
        </div>
        <div v-show="loginCode.step == 2" style="margin-top: 20px">
          <el-input
            clearable
            show-password
            style="width: 400px"
            size="small"
            v-model="loginCode.datas.password_confirm"
            placeholder="确认新密码"
          ></el-input>
        </div>
      </div>
      <el-row slot="footer">
        <el-col align="center">
          <el-button
            size="small"
            style="width: 88px"
            @click="loginCode.showLog = false"
          >
            取 消
          </el-button>
          <el-button
            v-show="loginCode.step == 1"
            size="small"
            style="width: 88px"
            type="primary"
            @click="loginCode.contentniue"
          >
            下一步
          </el-button>
          <el-button
            v-show="loginCode.step == 2"
            size="small"
            style="width: 88px"
            type="primary"
            @click="loginCode.submit"
          >
            确 定
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- end of 设置登录密码  -->
  </div>
</template>

<script>
import { get } from "@/server/ajax.js";
export default {
  data() {
    return {
      status: "0",
      userMsg: {
        company: {},
        staff: {},
        avatar: "",
      },
      company_id: 0,
      company: {
        name: "",
      },
      platInfo: {
        logo_url: "",
      },
      navBarTop: [],
      staffList: [],
      //登录设置
      loginCode: {
        showLog: false,
        company_id: null,
        step: 1,
        datas: {
          company_id: "",
          first_pwd: "",
          password: "",
          password_confirm: "",
        },
        open: (val) => {
          const login = this.loginCode;
          login.step = 1;
          login.datas.first_pwd = "";
          login.datas.password = "";
          login.datas.password_confirm = "";
          login.company_id = val;
          login.showLog = true;
        },
        contentniue: () => {
          if (this.loginCode.datas.first_pwd) {
            this.loginCode.step = 2;
          } else {
            this.$message.error("请先输入初始密码");
          }
        },
        submit: () => {
          if (
            this.loginCode.datas.password &&
            this.loginCode.datas.password_confirm
          ) {
            if (
              this.loginCode.datas.password ==
              this.loginCode.datas.password_confirm
            ) {
              let param = {
                ...this.loginCode.datas,
                company_id: this.loginCode.company_id,
              };
              this.$axios
                .postJSON("/base/user/company/resetpwd", param)
                .then((res) => {
                  if (res.code == 200) {
                    this.goToPage({ company_id: this.loginCode.company_id });
                  }
                });
            } else {
              this.$message.error("两次密码不一致，请重新输入");
            }
          } else {
            this.$message.error("请先输入密码");
          }
        },
      },
    };
  },
  created() {
    if (sessionStorage.getItem("AccessToken")) {
      this.status = "1";
      get("/base/user/login_info").then((res) => {
        let userMsg = res.data;
        res.data.avatar = `${this.$utils.imgUrl}/user/avatar/${userMsg.user_id}`;
        this.userMsg = res.data;
        this.company_id = userMsg.company.id;
      });
      this.getCompList();
    } else {
      this.status = "0";
    }
    this.getTopNavBar();
    this.$utils
      .getPlatformInfo()
      .then((res) => {
        this.platInfo = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {},
  methods: {
    mouseenterNav(val) {
      val.open = true;
    },
    getCompList() {
      this.$axios.get("/base/user/company").then((res) => {
        if (res.code == 200) {
          let array = res.data;
          this.staffList = res.data;
        }
      });
    },
    mainCompany(val) {
      this.$axios
        .postJSON("/base/user/login/company", {
          company_id: Number(val),
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("设置成功");
            let data = res.data;

            if (process.env.NODE_ENV === "production") {
              sessionStorage.setItem("AccessToken", data.AccessToken);
            }
            localStorage.setItem("AccessToken", data.AccessToken);
            

            this.$axios.get("/base/user/login_info").then((res) => {
              let userMsg = res.data;
              this.userMsg = res.data;
              this.company_id = userMsg.company.id;
              res.data.avatar = `https://avatar.${VUE_APP_API_URL}/staff/avatar/${userMsg.company.id}/${userMsg.staff.id}`;
            });
          }
        });
    },
    gotoOther() {
      if (!this.userMsg.company || !this.userMsg.company.id) {
        return console.log("没有 company id");
      }

      this.$axios
        .postJSON("/base/user/login/company", {
          company_id: this.userMsg.company.id,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.company_id) {
              this.loginCode.open(res.data.company_id);
            } else {
              if (process.env.NODE_ENV === "production") {
                sessionStorage.setItem("AccessToken", res.data.AccessToken);
                localStorage.setItem(
                  "time_token",
                  res.data.AccessExpire * 1000
                );
                localStorage.setItem("loading", "going");
              }
              localStorage.setItem("AccessToken", res.data.AccessToken);
              window.open("/main/index");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pageBtn(item) {
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    getTopNavBar() {
      this.$axios
        .get("/setting/page_top")
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            let topNav = data?.value?.top_nav;
            function dealChildren(item) {
              item.open = false;
              if (item.children && item.children.length > 0) {
                dealChildren(item.children);
              }
            }
            if (topNav && topNav.length > 0) {
              topNav.map((item, index) => {
                item.open = false;
                if (item.children && item.children.length > 0) {
                  dealChildren(item.children);
                }
              });
              this.navBarTop = topNav;
            }
          }
        })
        .catch((err) => {
          console.log("page_top", err);
        });
    },
    logins() {
      this.$router.push("/login");
    },
    qiyeruzhu(){
      this.$router.push("/register/settled");
    },
    resgister() {
      this.$router.push("/resgister");
    },
    loginOutBtn() {
      this.$confirm("确认是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          setTimeout(() => {
            if (process.env.NODE_ENV === "production") {
              sessionStorage.removeItem("AccessToken");
            } else {
              localStorage.removeItem("AccessToken");
            }
            this.$router.push("/login");
          }, 500);
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
$navHeight: 64px;
.header-margin {
  height: $navHeight;
}
.header {
  height: $navHeight;
  width: 100%;
  background-color: #fff;
  // box-shadow:0px 15px 10px -15px #CDCDCD;
  display: flex;
  justify-content: center;
  line-height: 60px;
  position: fixed;
  top: 0;
  z-index: 10;
  .leftTiles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    .company-info {
      display: flex;
      align-items: center;
      .icon {
        cursor: pointer;
        margin-right: 8px;
      }
      .companyInfo {
        cursor: pointer;
      }
      .avatar-img {
        width: 32px;
        height: 32px;
        margin-left: 12px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .leftTiles {
      padding: 0 15px;
    }
  }
}
// 顶部导航
.navs {
  height: $navHeight;
  display: flex;
  align-items: center;
  position: relative;
  .navs_content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .plat-logo {
      height: 32px;
      width: auto;
      cursor: pointer;
      margin-right: 15px;
    }
    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      & > div {
        padding: 0 15px;
        line-height: 48px;
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

.shows {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 9;
  z-index: 2000;
  color: #000;
  background-color: #fff;
  border-top: 1px solid #efefef;
  border-radius: 5px;
  padding: 12px 15px;
  display: flex;
  width: 1200px;
  box-sizing: border-box;
  &::before {
    content: "";
    display: block;
    height: 15px;
    background-color: transparent;
    width: 1200px;
    top: -10px;
    position: absolute;
    left: 0;
    z-index: 10;
  }
  & > div {
    width: 165px;
    margin-right: 20px;
  }
}
.erjis {
  // font-weight: 800;
  height: 30px;
  line-height: 30px;
  .el-icon {
    color: #606469;
  }
}
.sanjis {
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  // text-align: center;
  &:hover {
    color: #0088fe;
  }
}
.otherConted {
  line-height: 48px;
}
.login-flex {
  display: flex;
  align-items: center;
}
.logins {
  width: 96px;
  background-color: #f26161;
  text-align: center;
  color: #fff;
  user-select: none;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
}
.login {
  margin-right: 20px;
  color: red;
  user-select: none;
  cursor: pointer;
}

.mains {
  padding: 0;
  margin: 0;
  color: #666;
  & > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
    .imgss {
      display: flex;
      img {
        border-radius: 50%;
        margin-right: 10px;
      }
      .company-name {
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    line-height: 30px;
  }
  & > div:nth-child(2) {
    border-bottom: 1px solid #ebeaea;
    display: flex;
    padding: 10px;
    & > div {
      cursor: pointer;
      user-select: none;
      width: 33.33%;
      text-align: center;
      border-right: 1px solid #ebeaea;
    }
  }
  & > div:nth-child(3) {
    &:hover {
      background-color: #fafafa;
      cursor: pointer;
    }
    padding: 15px;
    display: flex;
    & > div:nth-child(1) {
      border: 2px solid #ccc;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 10px;
    }
    & > div:nth-child(2) {
      height: 14px;
      line-height: 14px;
    }
  }
  & > div:nth-child(4) {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background-color: #f3f3f3;
    text-align: center;
    color: #d89614;
  }
}
</style>
<style lang="scss" scoped>
.el-radio__input.is-checked + .el-radio__label {
  color: #f72626;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #f72626;
  border-color: #f72626;
}
</style>
